<!-- ReportsIndex.vue -->
<template>
  <SubNavCardVue :buttons="buttons">
    <router-view></router-view>
  </SubNavCardVue>
</template>

<script>
import SubNavCardVue from '../../components/cards/SubNavCard.vue'

export default {
  components: { SubNavCardVue },
  data() {
    return {
      buttons: [
        {
          label: 'État Journalière',
          icon: 'bi bi-clipboard-data-fill',
          route: 'reports-daily-status'
        },
        {
          label: 'Rapport Client',
          icon: 'bi bi-clipboard-data-fill',
          route: 'reports-customers-index'
        },
        {
          label: 'Rapport Employé',
          icon: 'bi bi-clipboard-data-fill',
          route: 'reports-employees-index'
        },
        {
          label: 'Rapport Journalière',
          icon: 'bi bi-clipboard-data-fill',
          route: 'reports-daily-sheet'
        },
        {
          label: 'Rapport d\'archives',
          icon: 'bi bi-archive-fill',
          route: 'sales-customer-report-archive'
        }
      ]
    }
  }
}
</script>